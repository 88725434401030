<template>
  <div>
    <b-input-group class="biz-input-group" prepend="第" append="号" :description="description">
      <validation-provider ref="constructionInputProvider" class="col-8 px-0" :name="name"  :rules="{required:isSelected == true}" v-slot="{ errors }">
        <b-form-input type="text" 
          :placeholder="placeholder"
          maxlength="6" 
          v-model="model" 
          :disabled="!isSelected" 
          v-b-popover.focus.top="'半角数字でご入力ください'" 
          @change="onInput"/>
        <div class="row mt-2">
          <div class="col-auto"><small>許認可を受けた<br/>建設業の種類</small></div>
          <div class="col">
            <table class="table-bordered w-100 construction-data-table">
              <tbody>
                <tr>
                  <td>土</td>
                  <td>建</td>
                  <td>大</td>
                  <td>左</td>
                  <td>と</td>
                  <td>石</td>
                  <td>屋</td>
                  <td>電</td>
                  <td>管</td>
                  <td>夕</td>
                  <td>鋼</td>
                  <td>筋</td>
                  <td>舗</td>
                  <td>し<br>ゅ</td>
                  <td>板</td>
                  <td>ガ</td>
                  <td>塗</td>
                  <td>防</td>
                  <td>内</td>
                  <td>機</td>
                  <td>絶</td>
                  <td>通</td>
                  <td>園</td>
                  <td>井</td>
                  <td>具</td>
                  <td>水</td>
                  <td>消</td>
                  <td>清</td>
                  <td>解</td>
                </tr>
                <tr>
                  <td class="inputable_td" :class="isSelected?'editable':''"><input type="text" maxlength="1" @change="onInput" v-model="licenseType[ 0]" :disabled="!isSelected" class="special_input"></td>
                  <td class="inputable_td" :class="isSelected?'editable':''"><input type="text" maxlength="1" @change="onInput" v-model="licenseType[ 1]" :disabled="!isSelected" class="special_input"></td>
                  <td class="inputable_td" :class="isSelected?'editable':''"><input type="text" maxlength="1" @change="onInput" v-model="licenseType[ 2]" :disabled="!isSelected" class="special_input"></td>
                  <td class="inputable_td" :class="isSelected?'editable':''"><input type="text" maxlength="1" @change="onInput" v-model="licenseType[ 3]" :disabled="!isSelected" class="special_input"></td>
                  <td class="inputable_td" :class="isSelected?'editable':''"><input type="text" maxlength="1" @change="onInput" v-model="licenseType[ 4]" :disabled="!isSelected" class="special_input"></td>
                  <td class="inputable_td" :class="isSelected?'editable':''"><input type="text" maxlength="1" @change="onInput" v-model="licenseType[ 5]" :disabled="!isSelected" class="special_input"></td>
                  <td class="inputable_td" :class="isSelected?'editable':''"><input type="text" maxlength="1" @change="onInput" v-model="licenseType[ 6]" :disabled="!isSelected" class="special_input"></td>
                  <td class="inputable_td" :class="isSelected?'editable':''"><input type="text" maxlength="1" @change="onInput" v-model="licenseType[ 7]" :disabled="!isSelected" class="special_input"></td>
                  <td class="inputable_td" :class="isSelected?'editable':''"><input type="text" maxlength="1" @change="onInput" v-model="licenseType[ 8]" :disabled="!isSelected" class="special_input"></td>
                  <td class="inputable_td" :class="isSelected?'editable':''"><input type="text" maxlength="1" @change="onInput" v-model="licenseType[ 9]" :disabled="!isSelected" class="special_input"></td>
                  <td class="inputable_td" :class="isSelected?'editable':''"><input type="text" maxlength="1" @change="onInput" v-model="licenseType[10]" :disabled="!isSelected" class="special_input"></td>
                  <td class="inputable_td" :class="isSelected?'editable':''"><input type="text" maxlength="1" @change="onInput" v-model="licenseType[11]" :disabled="!isSelected" class="special_input"></td>
                  <td class="inputable_td" :class="isSelected?'editable':''"><input type="text" maxlength="1" @change="onInput" v-model="licenseType[12]" :disabled="!isSelected" class="special_input"></td>
                  <td class="inputable_td" :class="isSelected?'editable':''"><input type="text" maxlength="1" @change="onInput" v-model="licenseType[13]" :disabled="!isSelected" class="special_input"></td>
                  <td class="inputable_td" :class="isSelected?'editable':''"><input type="text" maxlength="1" @change="onInput" v-model="licenseType[14]" :disabled="!isSelected" class="special_input"></td>
                  <td class="inputable_td" :class="isSelected?'editable':''"><input type="text" maxlength="1" @change="onInput" v-model="licenseType[15]" :disabled="!isSelected" class="special_input"></td>
                  <td class="inputable_td" :class="isSelected?'editable':''"><input type="text" maxlength="1" @change="onInput" v-model="licenseType[16]" :disabled="!isSelected" class="special_input"></td>
                  <td class="inputable_td" :class="isSelected?'editable':''"><input type="text" maxlength="1" @change="onInput" v-model="licenseType[17]" :disabled="!isSelected" class="special_input"></td>
                  <td class="inputable_td" :class="isSelected?'editable':''"><input type="text" maxlength="1" @change="onInput" v-model="licenseType[18]" :disabled="!isSelected" class="special_input"></td>
                  <td class="inputable_td" :class="isSelected?'editable':''"><input type="text" maxlength="1" @change="onInput" v-model="licenseType[19]" :disabled="!isSelected" class="special_input"></td>
                  <td class="inputable_td" :class="isSelected?'editable':''"><input type="text" maxlength="1" @change="onInput" v-model="licenseType[20]" :disabled="!isSelected" class="special_input"></td>
                  <td class="inputable_td" :class="isSelected?'editable':''"><input type="text" maxlength="1" @change="onInput" v-model="licenseType[21]" :disabled="!isSelected" class="special_input"></td>
                  <td class="inputable_td" :class="isSelected?'editable':''"><input type="text" maxlength="1" @change="onInput" v-model="licenseType[22]" :disabled="!isSelected" class="special_input"></td>
                  <td class="inputable_td" :class="isSelected?'editable':''"><input type="text" maxlength="1" @change="onInput" v-model="licenseType[23]" :disabled="!isSelected" class="special_input"></td>
                  <td class="inputable_td" :class="isSelected?'editable':''"><input type="text" maxlength="1" @change="onInput" v-model="licenseType[24]" :disabled="!isSelected" class="special_input"></td>
                  <td class="inputable_td" :class="isSelected?'editable':''"><input type="text" maxlength="1" @change="onInput" v-model="licenseType[25]" :disabled="!isSelected" class="special_input"></td>
                  <td class="inputable_td" :class="isSelected?'editable':''"><input type="text" maxlength="1" @change="onInput" v-model="licenseType[26]" :disabled="!isSelected" class="special_input"></td>
                  <td class="inputable_td" :class="isSelected?'editable':''"><input type="text" maxlength="1" @change="onInput" v-model="licenseType[27]" :disabled="!isSelected" class="special_input"></td>
                  <td class="inputable_td" :class="isSelected?'editable':''"><input type="text" maxlength="1" @change="onInput" v-model="licenseType[28]" :disabled="!isSelected" class="special_input"></td>
                </tr>
              </tbody>
            </table>
            <small>1:一般建設業許可、2:特定建設業許可</small>
          </div>
        </div>
        <span class="text-danger"><small>{{ errors[0] }}</small></span>
      </validation-provider>
    </b-input-group>
  </div>
</template>

<script>
export default {
  props: ["code", "type", "value" , "placeholder", "description", "data", "isSelected","name"],
  data: ()=>({
    model: null,
    licenseType: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
  }),
  created(){
    if(this.value){
      let datas = this.value.split(',');
      
      this.model = datas[0];
      if(datas.length > 1){
        this.licenseType = datas[1].split('');
      }
    }
    for(let i=0; i < this.licenseType.length; i++){
      if(this.licenseType[i] == 0) this.licenseType[i] = '';
    }
  },
  methods:{
    onInput(){
      if(!this.licenseType.some(l => l !== '')){
        this.$refs.constructionInputProvider.setErrors(["建設業法は必須項目です"]);
      }
      this.$emit('onInput', this.code, 
        this.model + ',' + this.licenseType.map(l => !l ? 0 : l).join(''),
        this.model //TODO add type
      );
    }
  }
}
</script>

<style lang="scss">
  .construction-data-table{
    tr{
      td{
        text-align: center;
        &.inputable_td{
          padding:0;
          background: none;
          &.editable{
            background: #FFF;
          }
          .special_input{
            border:none;
            background: #fff;
            color: #495057;
            padding: 1px;
            width: 20px !important;
            margin:0;
            text-align: center;
            &:disabled{
              background: none;
            }
          }
        }
      }
    }
  }
</style>