<template>
  <div>
    <b-alert show variant="light" class="mt-2 col-8">
      <div class="small" v-if="!licensing.files || licensing.files.length <= 0">
        <p style="margin-top: 6px">自動許認可チェック失敗したため、再確認及び許認可の資料をアップロードする必要があります。</p>
        <p class="text-danger">原因：{{licensing.verificationResult.failedReason || 'システムエラー'}}</p>
      </div>
      <div class="row">
        <div class="col-12">
          <template v-if="licensing.files && licensing.files.length > 0">
            <b-list-group>
              <b-list-group-item class="d-flex" v-for="(file, index) in licensing.files" :key="index">
                <a href="javascript:void(0);" @click="onFileDownload(file)">{{file.name}}</a>
                <a href="javascript:void(0);" @click="onFileRemove(file)" class="ml-auto"><font-awesome-icon :icon="['fas', 'times']"></font-awesome-icon></a>
              </b-list-group-item>
            </b-list-group>
          </template>
          <template v-else>
            <b-checkbox size="sm" v-model="isManual">ウェブサイトに未登録ため、エビデンスファイルをアップロードして検証する</b-checkbox>
            <validation-provider class="d-block mt-2" v-if="isManual" :name="name" :rules="{required:isSelected == true}" v-slot="{ errors }">
              <b-form-file @input="onFileUpload" multiple no-traverse accept="image/jpg, image/png, application/pdf" v-model="files"/>
              <span class="invalid-feedback"><small>{{ errors[0] }}</small></span>
            </validation-provider>
          </template>
        </div>
      </div>
    </b-alert>
  </div>
</template>
<script>
import FileUpload from '@/model/FileUpload'
export default {
  props: ["licensing", "isSelected", "name"],
  data: function(){
    return {
      isManual: false,
      files: []
    }
  },
  methods:{
    onFileUpload(){
      let files = this.files;
      let promises = [];
      for(let i=0; i < this.files.length; i++){
        promises.push(FileUpload.save(
          this.$store.state.user.companyCode,
          this.files[i],
          "document"
        ));
      }
      Promise.all(promises).then(results => {
        this.licensing.files = [];
        for(let i = 0; i < results.length; i++){
          this.licensing.files.push({
            code: results[i].data.path,
            name: files[i].name
          });
        }
        this.$emit("change", this.licensing);
      }).catch(reason => this.$errorMessage(undefined, reason));
    },
    onFileRemove(file){
      this.licensing.files = this.licensing.files.filter(f => f.code !== file.code);
      this.$emit("change", this.licensing);
    },
    onFileDownload(file){
      FileUpload.download( this.$store.state.user.companyCode, file.code).catch(reason => this.$errorMessage(undefined, {reason}));
    }
  }
}
</script>