<template>
  <div>
    <ul class="biz-breadcrumb" v-if="!isRegistrationWizard">
      <li><router-link to="/supplier/manage">{{$t('SYSTEM_MANAGEMENT.TITLE')}}</router-link></li>
      <li><a>{{$t('VERIFICATION_INFO.TITLE')}}</a></li>
    </ul>
    <div class="page">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(onSave)">
          <div class="page-title" v-if="!isRegistrationWizard">
            <h1>{{$t('VERIFICATION_INFO.TITLE')}}</h1>
          </div>
          <div class="card biz-primary mt-3">
            <div class="card-header">
              {{$t('VERIFICATION_INFO.HEADER_PERMIT')}}
              <p>{{$t('VERIFICATION_INFO.HEADER_PERMIT_DESC')}}</p>
            </div>
            <div class="card-body">
              <div class="mt-3" v-if="form.licenses.length <= 0">
                <p>{{$t('VERIFICATION_INFO.PERMIT_NOT_NEEDED')}}</p>
              </div>
              <div class="biz-form-group mt-3" v-for="(cl, index) in form.licenses" v-bind:key="cl.license.id">
                <label class="biz-form-label required" style="flex:2.5">
                  <span v-html="cl.license.label"></span> 
                  <RPAExplain :code="cl.license.code"/>
                </label>
                <div class="biz-form-body">
                  <template v-if="cl.license.code === 'CONSTRUCTION'">
                    <ConstructionInput
                      :isSelected="true"
                      :name="cl.license.label"
                      :code="cl.license.code"
                      :type="cl.license.inputType"
                      :value="cl.value"
                      :placeholder="cl.license.placeholder"
                      :description="cl.license.description"
                      :data="cl.license.data"
                      @onInput="onInput"/>
                  </template>
                  <template v-else-if="cl.license.code === 'WORKER'">
                    <WorkerInput
                      :isSelected="true"
                      :name="cl.license.label"
                      :code="cl.license.code"
                      :type="cl.license.inputType"
                      :value="cl.value"
                      :placeholder="cl.license.placeholder"
                      :description="cl.license.description"
                      :data="cl.license.data"
                      @onInput="onInput"/>
                  </template>
                  <template v-else>
                    <CustomInput 
                    :isSelected="true"
                    :name="cl.license.label"
                    :code="cl.license.code"
                    :type="cl.license.inputType"
                    :dataType="cl.license.type"
                    :value="cl.value"
                    :placeholder="cl.license.placeholder"
                    :description="cl.license.description"
                    :data="cl.license.data"
                    @onInput="onInput"/>
                  </template>
                  <template v-if="cl.verificationResult && !cl.verificationResult.isCorrect">
                    <ManualUploadInput :licensing="cl" :name="cl.license.label" :isSelected="true" @change="onManualUpload($event, index, 'LICENSE')"/>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div class="card biz-primary mt-4">
            <div class="card-header">
              {{$t('VERIFICATION_INFO.HEADER_CERTIFICATE')}}
              <p>{{$t('VERIFICATION_INFO.HEADER_CERTIFICATE_DESC')}}</p>
            </div>
            <div class="card-body">
              <div class="mt-3" v-if="form.certificates.length <= 0">
                <p>{{$t('VERIFICATION_INFO.CERTIFICATE_NOT_NEEDED')}}</p>
              </div>
              <div class="biz-form-group mt-3" v-for="(fc, index) in form.certificates" v-bind:key="fc.license.id">
                <label class="biz-form-label required" style="flex:2.5">
                  <span v-html="fc.license.label"></span> 
                  <RPAExplain :code="fc.license.code"/>
                </label>
                <div class="biz-form-body">
                  <template>
                    <CustomInput 
                      :isAcquired="fc.isAcquired"
                      :isSelected="!!fc.id"
                      :name="fc.license.label"
                      :code="fc.license.code"
                      :type="fc.license.inputType" 
                      :dataType="fc.license.type"
                      :value="fc.value" 
                      :placeholder="fc.license.placeholder" 
                      :description="fc.license.description"
                      :data="fc.license.data"
                      @onInput="onInput"/>
                    
                  </template>
                  <template v-if="fc.verificationResult && !fc.verificationResult.isCorrect">
                    <ManualUploadInput :licensing="fc" :name="fc.license.label" :isSelected="true" @change="onManualUpload($event, index, 'CERTIFICATE')"/>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div class="card biz-primary mt-4">
            <div class="card-header">
              {{$t('VERIFICATION_INFO.HEADER_PATENT')}}
              <p>{{$t('VERIFICATION_INFO.HEADER_PATENT_DESC')}}</p>
            </div>
            <div class="card-body">
              <div class="biz-form-group">
                <label class="biz-form-label optional">
                  {{$t('VERIFICATION_INFO.PATENT')}}
                  <RPAExplain code='PATENT'/>
                </label>
                <div class="biz-form-body">
                  <table class="table table-bordered mb-0">
                    <colgroup>
                      <col width="22.5%"/>
                      <col width="22.5%"/>
                      <col width="22.5%"/>
                      <col width="22.5%"/>
                      <col width="10%"/>
                    </colgroup>
                    <thead>
                      <tr>
                        <td>{{$t('VERIFICATION_INFO.PATENT_NUMBER')}}</td>
                        <td>{{$t('VERIFICATION_INFO.PATENT_DATE')}}</td>
                        <td>{{$t('VERIFICATION_INFO.PATENT_DOC_NUMBER')}}</td>
                        <td>{{$t('VERIFICATION_INFO.PATENT_NAME')}}</td>
                        <td></td>
                      </tr>
                    </thead>
                  </table>
                  <table v-if="form.patents.length <= 0" class="table table-bordered">
                    <tr>
                      <td colspan="5">{{$t('VERIFICATION_INFO.NO_PATENT_TO_DISPLAY')}}</td>
                    </tr>
                  </table>
                  <table v-else v-for="(patent, index) in form.patents" v-bind:key="patent.rowNumber" class="table table-bordered mb-0">
                    <colgroup>
                      <col width="22.5%"/>
                      <col width="22.5%"/>
                      <col width="22.5%"/>
                      <col width="22.5%"/>
                      <col width="10%"/>
                    </colgroup>
                    <tbody>
                      <tr>
                        <td>
                          <validation-provider :name="$t('VERIFICATION_INFO.PATENT_NUMBER') + (index + 1)" :rules="{required: false, regex: /^特願[0-9]{4}-[0-9]{6}$/}" v-slot="{ errors }">
                            <b-form-input type="text" :placeholder="$t('VERIFICATION_INFO.PATENT_NUMBER_PLACEHOLDER')" v-model="patent.applicationNumber">
                            </b-form-input>
                            <span class="invalid-feedback text-left">{{ errors[0] }}</span>
                          </validation-provider>
                        </td>
                        <td>
                          <validation-provider :name="$t('VERIFICATION_INFO.PATENT_DATE') + (index + 1)" :rules="{required: false, regex: /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/ }" v-slot="{ errors }">
                            <b-form-input   type="text" :placeholder="$t('VERIFICATION_INFO.PATENT_DATE_PLACEHOLDER')" v-model="patent.applicationDate">
                            </b-form-input>
                            <span class="invalid-feedback text-left">{{ errors[0] }}</span>
                          </validation-provider>
                        </td>
                        <td>
                          <validation-provider  :name="$t('VERIFICATION_INFO.PATENT_DOC_NUMBER') + (index + 1)" :rules="{required: false, regex: /^特開[0-9]{4}-[0-9]{6}$/ }" v-slot="{ errors }">
                            <b-form-input   type="text" :placeholder="$t('VERIFICATION_INFO.PATENT_DOC_NUMBER_PLACEHOLDER')" v-model="patent.referenceNumber">
                            </b-form-input>
                            <span class="invalid-feedback text-left">{{ errors[0] }}</span>
                          </validation-provider>
                        </td>
                        <td>
                          <validation-provider  :name="$t('VERIFICATION_INFO.PATENT_NAME') + (index + 1)" :rules="{required: false}" v-slot="{ errors }">
                            <b-form-input type="text" :placeholder="$t('VERIFICATION_INFO.PATENT_NAME_PLACEHOLDER')" v-model="patent.patentName"/>
                            <span class="invalid-feedback text-left">{{ errors[0] }}</span>
                          </validation-provider>
                        </td>
                        <td :rowspan="patent.verificationResult && !patent.verificationResult.isCorrect ? '2': '1'">
                          <a href="javascript:void(0);" v-on:click="onAddPatent">
                            <font-awesome-icon icon="plus"></font-awesome-icon> 追加
                          </a><br/>
                          <a href="javascript:void(0);" v-if="index > 0" v-on:click="onRemovePatent(index)">
                            <font-awesome-icon icon="minus"></font-awesome-icon> 削除
                          </a>
                        </td>
                      </tr>
                      <tr v-bind:key="patent.rowNumber + '_manual'" v-if="patent.verificationResult && !patent.verificationResult.isCorrect">
                        <td colspan="4">
                          <ManualUploadInput :licensing="patent" :name="'特許' + (index+1)" :isSelected="true" @change="onManualUpload($event, index, 'PATENT')"/>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="text-center mt-5">
            <j-button variant="primary" size="lg" buttonType="submit">
              <template v-if="isRegistrationWizard">
                {{$t('COMMON.NEXT')}}
              </template>
              <template v-else>
                {{$t('COMMON.SAVE')}}
              </template>
            </j-button>
            <div class="mt-4" v-if="!isRegistrationWizard">
              <j-button variant="light" buttonType="button" to="/supplier/manage">
                <font-awesome-icon icon="arrow-left"></font-awesome-icon>
                {{$t('COMMON.GO_BACK')}}
              </j-button>
            </div>
          </div>
        </form>
      </ValidationObserver> 
    </div>
  </div>
</template>

<style scoped lang="scss">
  .btn-remove{
    width:90px;
    .btn{
      margin-top:3px;
      min-width:60px;
    }  
  }
</style>
<script>
  import CompanyInfo from '@/model/CompanyInfo';

  import CustomInput from '@/components/rpa/CustomInput.vue'
  import ConstructionInput from '@/components/rpa/ConstructionInput.vue'
  import WorkerInput from '@/components/rpa/WorkerInput.vue'
  import ManualUploadInput from '@/components/rpa/ManualUploadInput.vue'
  import RPAExplain from '@/components/rpa/RPAExplain.vue'
  
  export default {
    props: ["isRegistrationWizard"],
    components: { CustomInput, ConstructionInput, WorkerInput, ManualUploadInput, RPAExplain },
    data(){
      return {
        form: {
          licenses:[],
          certificates:[],
          patents: []
        }
      } 
    },
    mounted: function(){
      this.load();
    },
    methods:{
      load(){
        this.form = {
          licenses:[],
          certificates:[],
          patents: []
        };
        Promise.all([
          CompanyInfo.getCompanyLicenses(this.$store.state.user.companyCode),
          CompanyInfo.getCompanyPatents(this.$store.state.user.companyCode)
        ]).then(results=> {
          this.form.licenses = results[0].data.filter(i => i.license.type === 'LICENSE');
          this.form.certificates = results[0].data.filter(i => i.license.type   === 'CERTIFICATE');
          //Set patents
          this.form.patents = results[1].data;
          if(this.form.patents.length <= 0) this.onAddPatent();
          this.form.patents.forEach((p, i) => {p.rowNumber = i;});
        }).catch(reason => this.$errorMessage(undefined, {reason}));
      },
      onInput(code, value, displayValue, isAcquired){
        let l = this.form.licenses.find(fl => fl.license.code === code);
        if(l){
          l.value = value;
          l.displayValue = displayValue;
        } 
        
        let c = this.form.certificates.find(fc => fc.license.code === code);
        if(c) {
          c.value = value;
          c.displayValue = value;
          c.isAcquired = isAcquired;
        }
      },
      onAddPatent(){
        this.form.patents.push({rowNumber: this.form.patents.length});
      },
      onRemovePatent(index){
        this.form.patents.splice(index,1)
        for(var i = 0; i < this.form.patents.length;i++){
          this.form.patents[i]['rowNumber'] = i
        }
      },
      onSave: async function(){
        //TODO dirty hack
        let popupRes = true;
        if(this.$store.state.user && this.$store.state.user.status.registrationCompleted){
          popupRes = await this.$bvModal.msgBoxConfirm(this.$t('VERIFICATION_INFO.CONFIRM_MESSAGE'), {
            okTitle: this.$t('COMMON.EDIT'),
            cancelTitle: this.$t('COMMON.CANCEL')
          })
        }
        if(popupRes){
          this.form.patents = this.form.patents.filter(p => p.applicationNumber && p.applicationDate && p.referenceNumber)
          Promise.all([
            CompanyInfo.saveCompanyLicenses(this.$store.state.user.companyCode,{ 
              licenses: this.form.licenses.concat(this.form.certificates)
            }),
            CompanyInfo.saveCompanyPatents(this.$store.state.user.companyCode,{ patents: this.form.patents })
          ]).then(()=> {
            if(this.$store.state.user && this.$store.state.user.status.registrationCompleted){
              this.$successMessage(this.$t('VERIFICATION_INFO.SUCCESS_MESSAGE_REQUEST'));
            }else{
              this.$successMessage(this.$t('VERIFICATION_INFO.SUCCESS_MESSAGE_REQUEST'));
            }
            
            if(this.isRegistrationWizard){
              this.$emit('onSave', 'CategoryInfo');
              this.load();
            }else{
              this.$router.push('/supplier/manage');
            }
          }).catch(reason => this.$errorMessage(undefined, {reason})); 
        }
      },
      onManualUpload(licensing, index, type){
        if(type === 'LICENSE'){
          this.$set(this.form.licenses, index, licensing);
        }else if(type === 'CERTIFICATE'){
          this.$set(this.form.certificates, index, licensing);
        }else{
          this.$set(this.form.patents, index, licensing);
        }
      }
    }
  }
</script>
