<template>
  <div>
    <div class="row">
      <div class="col">
        <validation-provider :name="'労働者派遣事業許可番号（届出受理番号）'"  :rules="{required:isSelected == true}" v-slot="{ errors }">
          <div class="d-flex">
            <b-form-select style="flex: 0; min-width: 100px;" class="biz-offset" :disabled="!isSelected" v-model="combo" :value="null" :options="options" @change="onInput"></b-form-select>
            <b-input-group style="flex: 0; min-width: 50px; margin-left: 1rem;" class="biz-input-group biz-offset" :description="description">
              <b-form-input type="text" 
                v-b-popover.focus.top="'半角数字でご入力ください'" 
                v-model="model1" maxlength="2" placeholder="例）01" :disabled="!isSelected" @change="onInput">
              </b-form-input>
            </b-input-group>
            <span style="flex: 0;" class="pt-2 mx-1 biz-offset">-</span>
            <b-input-group style="flex: 0; min-width: 120px;" class="biz-input-group biz-offset" :description="description">
              <b-form-input type="text" 
                v-b-popover.focus.top="'半角数字でご入力ください'" 
                v-model="model2" maxlength="6"  placeholder="例）000001" :disabled="!isSelected" @change="onInput">
              </b-form-input>
            </b-input-group>
          </div>
          <span class="text-danger"><small>{{ errors[0] }}</small></span>
        </validation-provider>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["code", "type", "value" , "placeholder", "description", "data", "isSelected","name"],
  data: ()=>({
    model1: null,
    model2: null,
    combo: null,
    options: []
  }),
  created(){
    if(this.value){
      let datas = this.value.split(',');
      
      this.model1 = datas[0];
      if(datas.length >= 1){
        this.model2 = datas[1];
      }
      if(datas.length >= 2){
        this.combo = datas[2];
      }
    }
    if(this.data){
      this.options = JSON.parse(this.data);
    }
  },
  methods:{
    onInput(){
      this.$emit('onInput', this.code, 
        this.model1 + ',' + this.model2 + ','  + this.combo, 
        this.options.find(o => o.value == this.combo).text + ' ' + this.model1 + '-' + this.model2);
    }
  }
}
</script>

<style>

</style>