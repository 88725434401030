<template>
  <div>
    <div class="row" v-if="type==='text'">
      <validation-provider class="col-4" :name="name"  :rules="{required:unacquired === false}" v-slot="{ errors }">
        <div class="d-flex al-child-mid">
          <b-input-group class="biz-input-group" :description="description">
            <b-form-input type="text" 
              v-b-popover.focus.top="'半角でご入力ください'" 
              :placeholder="placeholder" v-model="model" :disabled="unacquired === true" @change="onInput">
            </b-form-input>
          </b-input-group>
          <b-checkbox @change="onInput" style="flex:none; margin-left: 10px; margin-top: 6px;" size="sm" v-if="dataType === 'CERTIFICATE'" v-model="unacquired">取得なし</b-checkbox>
        </div>
        <span class="text-danger"><small>{{ errors[0] }}</small></span>
      </validation-provider>
    </div>
    <div class="row" v-else-if="type === 'no'">
      <validation-provider class="col-7 px-0" :name="name"  :rules="{required:isSelected == true}" v-slot="{ errors }">
        <b-input-group class="biz-input-group" prepend="第" append="号" :description="description">
          <b-form-input class="rounded-0" 
            v-b-popover.focus.top="'半角数字でご入力ください'" 
            type="text" :placeholder="placeholder" v-model="model" :disabled="!isSelected" @change="onInput">
          </b-form-input>
          <span class="text-danger"><small>{{ errors[0] }}</small></span>
        </b-input-group>
      </validation-provider>
    </div>
    <div class="row" v-else-if="type === 'combo+text'">
      
        <validation-provider class="col-2" :name="name"  :rules="{required:isSelected == true}">
          <b-form-select :disabled="!isSelected" v-model="combo" :value="null" :options="options" @change="onInput"></b-form-select>
        </validation-provider>
        
        <validation-provider class="col-4" :name="name"  :rules="{required:isSelected == true}" v-slot="{ errors }">
          <b-input-group class="biz-input-group"  prepend="第" append="号" :description="description">
            <b-form-input type="text" 
              v-b-popover.focus.top="'半角数字でご入力ください'" 
              :placeholder="placeholder" v-model="model" :disabled="!isSelected" @change="onInput">
            </b-form-input>
          </b-input-group>
          <span class="text-danger"><small>{{ errors[0] }}</small></span>
        </validation-provider>
      
    </div>
  </div>
</template>

<script>
export default {
  props: ["code", "type", "dataType", "value" , "placeholder", "description", "data", "isSelected", "name", "isAcquired"],
  data(){
    let data = {
      model: null,
      combo: null,
      unacquired: false,
      options: []
    }
    if(this.isSelected && this.dataType === 'CERTIFICATE'){
      data.unacquired = !this.isAcquired;
    }
    return data;
  },
  created(){
    if(this.data){
      this.options = JSON.parse(this.data);  
    }
    this.update();
  },
  watch:{
    value(){
      this.update();
    }
  },
  methods:{
    update(){
      if(this.value){
        let datas = this.value.split(',');
        this.model = datas[0];
        if(datas.length > 1){
          this.combo = datas[1];
        }
      }else{
        this.model = '';
      }
    },
    onInput(){
      if(this.unacquired){
        this.model = '';
        this.$emit('onInput', this.code, '', '', !this.unacquired)
      }else{
        this.$emit('onInput', this.code, 
          (this.model || '') + (this.combo ? "," + this.combo : ''),
          (this.combo ? this.options.find(o => o.value === this.combo).text + ' ' : '') + (this.model || ''),
          !this.unacquired)
      }
    }
  }
}
</script>

<style>

</style>