<template>
  <font-awesome-icon icon="question-circle" class="text-muted ml-1" v-b-popover.hover.right="{
    customClass: 'multi-line', content: text, html: true
  }"/>
</template>

<script>
export default {
  props: ["code"],
  data(){
    return {
      text: ""
    }
  },
  created(){
    switch(this.code){
      case "WORKER": this.text = '厚生労働省の人材サービス総合サイトに登録されている<br/>許可・届出受理番号をご入力ください。';break;
      case "CONSTRUCTION": this.text = '国土交通省の建設業者・宅建業者等<br/>企業情報検索システムに登録されている情報をご入力ください。';break;
      case "RESIDENTIAL": this.text = '国土交通省の建設業者・宅建業者等<br/>企業情報検索システムに登録されている情報をご入力ください。';break;
      case "TRAVEL_AGENCY": this.text = '日本旅行業協会に登録されている<br/>情報をご入力ください。';break;
      case "INDUSTRIAL_WASTE": this.text = '環境省の産業廃棄物処理業者情報<br/>検索システムに登録されている許可番号をご入力ください。';break;
      case "ISO9001": this.text = '日本適合性認定協会に登録されている<br/>登録番号をご入力ください。';break;
      case "ISO14001": this.text = '日本適合性認定協会に登録されている<br/>登録番号をご入力ください。';break;
      case "ISMS": this.text = '日本適合性認定協会あるいはISMS認証取得組織検索に<br/>登録されている登録番号をご入力ください。';break;
      case "PRIVACY_MARK": this.text = 'プライバシーマーク取得企業検索に<br/>登録されている情報をご入力ください。';break;
      case "PATENT": this.text = "J-PlatPat（特許情報プラットフォーム）に<br/>登録されている情報をご入力ください。"; break;
    }
  }
}
</script>

<style>

</style>